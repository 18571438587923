import styles from './dialog.module.css'

export const SearchButton = styled.button(`
  bg-gray-100
  outline-1
  outline-transparent
  cursor-text
  rounded-full
  w-full
  h-10
  px-5
  flex
  items-center
  font-medium
  text-gray-600
  flex-auto
  text-sm
`)



export const Dialog = styled.dialog([
  styles.dialog,
  `
  bg-white
  backdrop:bg-black
  forced-colors:border-1
  w-full
  h-full
  max-w-none
  max-h-fit
  md:mx-auto
  md:max-w-[58rem]
  md:rounded-3xl
  md:shadow-2xl
  md:w-auto
  md:h-auto
  pb-4
  `,
])

export const Toolbar = styled.div(`
  flex
  items-center
  px-4
  py-2
  lg:py-4
  mb-4
`)

export const SearchInputContainer = styled.div(`
  rounded-full
  p-3
  h-10
  flex
  items-center
  bg-gray-100
  forced-colors:outline-1
  shadow-sm
  w-full
  py-2
  pl-3
  pr-2
  gap-2
`)

export const SearchInput = styled.input(`
  w-full
  block
  border-0
  placeholder:text-gray-500
  font-medium
  outline-0
  bg-transparent
  p-0
`)


export const Groups = styled.div(`
  flex
  flex-col
  gap-8
  overflow-y-auto
  px-4
  pb-4
`)

export const Group = styled.div(`
`)

export const GroupName = styled.div(`
  text-sm
  font-medium
  text-gray-500
  px-2
  mb-2
`)

export const Results = styled.div(`
  flex
  flex-col
`)

export const Result = styled.a(({ isSelected }: { isSelected: boolean }) => [
  `
  w-full
  flex
  items-center
  gap-6
  px-2
  py-2
  cursor-pointer
  rounded-xl
  hover:bg-gray-100
  `,
  {
    'bg-gray-100': isSelected,
  },
])

export const ResultContent = styled.div(`
  w-full
`)

export const ResultTitle = styled.div(`
  font-medium
`)

export const ResultDescription = styled.div(`
  text-sm
  line-clamp-2
  text-gray-700
`)



export const CloseButton = styled.div(`
  pl-4
  py-3
  pr-4
  -my-3
  -mr-4
  cursor-pointer
  group/button
`)