import useFetch from '#/hooks/useFetch'
import { ButtonDiv, Icon } from '@/common/index'
import { useBreadcrumbReadContext } from '#/hooks/useBreadcrumbs'
import Lock from '@/FocusLock'

import { useAtom } from './atom.js'
import {
  defaultResults,
  groups,
  groupPositions,
} from './config.js'

import {
  CloseButton,
  Dialog,
  Group,
  GroupName,
  Groups,
  Result,
  ResultContent,
  ResultDescription,
  ResultTitle,
  Results,
  SearchButton,
  SearchInput,
  SearchInputContainer,
  Toolbar,
} from './components.js'

import type {
  SearchResult,
} from './types.js'

import ErrorBoundary from '@/common/ErrorBoundary'



export default function Search() {
  const [{
    input,
    results,
    selectedId,
    isShowing,
  }, actions] = useAtom({
    baseResults: defaultResults,
  })

  const query = useDeferredValue(input)
  const selected = useRef<SearchResult>()

  useLayoutEffect(() => {
    selected.current = results.find(r => r.id === selectedId) ?? null
  }, [selectedId, results])

  const onInput = useCallback((event: Dx.Event.Keyboard<'input'>) =>
    actions.setInput(event.target.value)
  , [actions])

  const crumbs = useBreadcrumbReadContext()

  useLayoutEffect(() => {
    if (!crumbs.isLoading) {
      actions.updateBaseResults(crumbs.breadcrumbs.map(crumb => ({
        id: `${crumb.group}-${crumb.key}`,
        group: crumb.group,
        title: crumb.title,
        description: crumb.snippet ?? '',
        url: crumb.url,
        score: 1,
      })))
    }
  }, [crumbs, actions])


  useHotKey('/', event => {
    event.preventDefault()
    actions.show()
  }, {
    enabled: !isShowing,
  })

  useHotKey(['down', 'up'], event => {
    event.preventDefault()
    actions.move(event.key === 'ArrowDown' ? 1 : -1)
  }, {
    repeatable: true,
    interactive: true,
    enabled: isShowing,
  })

  useHotKey('enter', event => {
    if (selected.current?.url) {
      void navigate(selected.current.url)
    }

    event.preventDefault()
    actions.hide()
  }, {
    enabled: isShowing,
    interactive: true,
  })

  useFetch({
    url: '/api/search',
    query: { query },
    isEnabled: is.present(query),
    onData: actions.setResults,
    throttle: 200,
  })

  const inputRef = useRef<'input'>()

  const grouped = results
    .sortBy(result => groupPositions[result.group])
    .groupedEntries('group')

  const dialog = useRef<'dialog'>()

  const onDialogClose = useCallback(() => {
    actions.reset()
  }, [actions])

  const onDialogClick = useCallback((event: Dx.Event.Mouse<'dialog'>) => {
    if (event.target?.tagName === 'DIALOG') {
      actions.reset()
    }
  }, [actions])

  useEffect(() => {
    if (isShowing) {
      dialog.current?.showModal()
      setTimeout(() => inputRef.current?.focus(), 150)

    } else {
      dialog.current?.close()
    }
  }, [isShowing])


  return <>
    <SearchButton onClick={actions.show}>
      <Icon
        className='mr-2 text-black'
        name='search'
        strokeWidth='2.5' />
      Search
    </SearchButton>

    <Dialog
      ref={dialog}
      onClose={onDialogClose}
      onClick={onDialogClick}>
      <ErrorBoundary>
        <Lock disabled={!isShowing}>
          <Toolbar>
            <SearchInputContainer>
              <Icon
                className='text-black'
                name='search'
                strokeWidth='2.5' />

              <label htmlFor='search' className='sr-only'>
                Search
              </label>

              <SearchInput
                autoFocus
                id='search'
                name='search'
                placeholder='Try searching "Diabetes"'
                type='text'
                disabled={!isShowing}
                onInput={onInput}
                ref={inputRef}
                value={query} />
            </SearchInputContainer>

            <CloseButton onClick={actions.reset}>
              <ButtonDiv size='round'>
                <Icon name='x' title='Close search' />
              </ButtonDiv>
            </CloseButton>
          </Toolbar>

          <Groups>
            {grouped.map(([group, groupResults]) => {
              const {
                name,
                title,
                description,
              } = groups[group]

              return (
                <Group key={group}>
                  <GroupName>{name}</GroupName>
                  <Results>
                    {groupResults.map(result =>
                      <Result
                        isSelected={result.id === selectedId}
                        onClick={actions.reset}
                        href={result.url}
                        key={result.id}>
                        <ResultContent>
                          <ResultTitle>{invoke(title, result) ?? result.title}</ResultTitle>
                          <ResultDescription>{invoke(description, result) ?? result.description}</ResultDescription>
                        </ResultContent>
                      </Result>
                    )}
                  </Results>
                </Group>
              )
            })}
          </Groups>
        </Lock>
      </ErrorBoundary>
    </Dialog>
  </>
}