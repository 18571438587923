import type {
  Actions,
  ActionHandlers,
  Config,
  Methods,
  Reducer,
  State,
} from './types.js'

export default function buildReducer<S extends State, A extends Actions, M extends Methods>(handlers: ActionHandlers<S, A, M>, config: Config<S, M>): Reducer<S> {
  const {
    debug,
    methods,
  } = config

  const log = debug ?
    (...args: any[]) => console.log(`[${typeof debug === 'string' ? debug : 'Matter'}]`, ...args) :
    () => {}

  return function (state, { type, payload }) {
    const fn = handlers[type]
    log(type, ...payload)
    __assert(fn || type === 'initialize', `Unknown message type '${type}'`)

    if (!fn) {
      return state
    }
    
    return produce(state, draft => {
      const methodsSelf = Object.mapValues(methods, fn_ => (...args_: any[]) => (fn_ as any).call(methodsSelf, draft, ...args_)) as M

      const self = {
        ...methodsSelf,
        ...Object.mapValues(handlers, fn_ => (...payload_: any[]) => (fn_ as any).call(self, draft, ...payload_)) as A,
      }

      ;(fn as any).call(self, draft, ...payload)
    })
  }
}
