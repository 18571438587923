import loader, { isAbortError, type LoaderOptions } from '#/loader'

type UseFetchOptions<I extends AnyRecord, R> = LoaderOptions<I> & {
  url: string,
  isEnabled?: boolean
  onData?: (results: R) => void
  onError?: (error: unknown) => void
  throttle?: number
}

export default function useFetch<I extends AnyRecord, R>(options: UseFetchOptions<I, R>) {
  const {
    isEnabled,
    onData: onData_,
    onError: onError_ = dx.capture,
    url,
    throttle,
    ...params_
  } = options

  const onData = useEvent(onData_)
  const onError = useEvent(onError_)
  const params = useMemoObject(params_)
  const timers = useTimers()

  const execute = useCallback(async (load: () => Promise<R>) => {
    try {
      const json = await load()

      if (isEnabled) {
        onData(json)
      }

    } catch (error) {
      if (!isAbortError(error)) {
        onError(error)
      }
    }
  }, [isEnabled, onData, onError])

  const throttledExecute = useMemo(() =>
    throttle ?
      timers.throttle(execute, throttle) :
      execute
  , [throttle, timers, execute])

  useEffect(() => {
    const [load, abort] = loader<I, R>(url, params)

    if (isEnabled) {
      void throttledExecute(load)
    }

    return abort
  }, [
    throttledExecute,
    params,
    url,
    isEnabled,
  ])
}
