import Navigation from './Navigation.js'
import Footer from './Footer.js'
import App from '@/App'
import Root from '@/Root'

export default function RootLayout({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <App>
      <Root>
        <Navigation />
        {children}
        <Footer />
      </Root>
    </App>
  )
}