import type { SearchResult } from './types.js'

export type Actions = {
  setInput: (input: string) => void
  setResults: (query: Dx.Api.Search.Result[]) => void
  reset: () => void
  clear: () => void
  clearOrReset: () => void
  show: () => void
  hide: () => void
  move: (by: number) => void
  updateBaseResults: (baseResults: SearchResult[]) => void
}

export type Input = {
  baseResults?: SearchResult[],
}

const threshold = 0.4

export const useAtom = Matter
  .state((input: Input) => ({
    isShowing: false,
    input: '',
    selectedId: null as string | null,
    hasSelected: false,
    results: input.baseResults ?? Array._,
    baseResults: input.baseResults ?? Array._,
  }))
  .actions<Actions>({
    setInput(state, input) {
      if (input === '/') {
        return
      }

      state.input = input
      state.hasSelected = false

      if (is.blank(input)) {
        state.results = state.baseResults
        state.selectedId = null
      }
    },
    setResults(state, results) {
      const next: SearchResult[] = state.results = results.map(result => ({
        ...result,
        group: 'search',
      }))

      if (next.first()?.score < threshold && state.input.length > 6) {
        next.unshift({
          id: '_',
          title: state.input,
          description: 'Explore these symptoms using our symptom checker.',
          url: dx.url('/symptom-checker', { query: state.input }),
          group: 'symptomsFresh',
          score: 1,
        })
      }

      if (next.length === 0) {
        state.results = state.baseResults
        state.selectedId = null

      } else if (!state.hasSelected || !next.some(r => r.id === state.selectedId)) {
        state.selectedId = next[0]?.id ?? null
      }
    },

    reset() {
      this.clear()
      this.hide()
    },

    clear(state) {
      state.input = ''
      state.results = state.baseResults
      state.hasSelected = false
      state.selectedId = null
    },

    clearOrReset(state) {
      state.input ?
        this.clear() :
        this.reset()
    },

    move(state, by) {
      state.hasSelected = true

      let index = state.selectedId == null ? -1 : state.results.findIndex(r => r.id === state.selectedId)


      if (index === -1) {
        state.selectedId = state.results[0]?.id ?? null

      } else {
        index += by
        index %= state.results.length
        state.selectedId = state.results.peek(index)?.id ?? null
      }
    },

    show(state) {
      state.isShowing = true
    },

    hide(state) {
      state.isShowing = false
    },

    updateBaseResults(state, baseResults) {
      state.baseResults = [
        ...baseResults,
        ...state.baseResults.filter(r => r.id.startsWith('~')),
      ]

      if (!state.input) {
        state.results = state.baseResults
      }
    },
  })
