import type {
  Actions,
  Draft,
  ActionHandlers,
  MethodHandlers,
  InitialState,
  Config,
  State,
  Methods,
} from './types.js'

export type { Actions } from './types.js'

import buildReducer from './buildReducer.js'
import useIsMounted from '#/hooks/useIsMounted'
import buildActions from './buildActions.js'


type NoInput = Tagged<EmptyObject, 'noInput'>

export default class Matter<const S extends State, I, M extends Methods> {
  #initialState: InitialState<S, I>
  #config: Config<S, M>

  static state<S_ extends State>(initialState: S_ | (() => S_)): Matter<S_, NoInput, EmptyObject>
  static state<S_ extends State, I_>(initialState: (input: I_) => S_): Matter<S_, I_, EmptyObject>
  static state<S_ extends State, I_>(initialState: InitialState<S_, I_>) {
    return new Matter<S_, I_, EmptyObject>(initialState, {
      methods: {},
      finalizer: noop,
    })
  }

  private constructor(initialState: InitialState<S, I>, config: Config<S, M>) {
    this.#initialState = initialState
    this.#config = config
  }


  debug(label?: string) {
    return new Matter<S, I, M>(this.#initialState, {
      ...this.#config,
      debug: label ?? true,
    })
  }

  finalizer(finalizer: (state: Draft<S>) => void) {
    return new Matter<S, I, M>(this.#initialState, {
      ...this.#config,
      finalizer,
    })
  }

  methods<const M_ extends Methods>(methods: MethodHandlers<S, M_>) {
    return new Matter<S, I, M_>(this.#initialState, {
      ...this.#config,
      methods,
    })
  }

  actions<const A_ extends Actions>(handlers: ActionHandlers<S, A_, M>) {
    const initialState = this.#initialState
    const config = this.#config

    type Args = I extends NoInput ? [] : [input: I]

    return function useMatter(...args: Args) {
      const isMounted = useIsMounted()
      const reducer = buildReducer<S, A_, M>(handlers, config)

      const state_ = useMemo(() => produce(
        invoke(initialState, args[0] as never),
        draft => {
          config.finalizer(draft)
        }
      ), []) // eslint-disable-line react-hooks/exhaustive-deps
    
      const [state, dispatch] = useReducer(reducer, state_)
      const actions = useMemo(() => buildActions<S, A_, M>(handlers, dispatch, isMounted), [dispatch, isMounted])
      useState(() => dispatch({ type: 'initialize', payload: [] }))

      return [
        state,
        actions,
      ] as const
    }
  }
}
