import logoDx from '&/logo-dx.svg'
import logo from '&/logo.svg'
import logoHealthline from '&/healthline/logo-healthline.svg'
import logoHealthlineH from '&/healthline/logo-healthline-h.svg'
import { Search } from '@/search/index'
import NavMenu from './NavMenu.js'
import ErrorBoundary from '@/common/ErrorBoundary'
 import { useFlag } from '@/developer/FlagProvider'

const Container = styled.div(`
  sticky
  top-0
  right-0
  left-0
  py-2
  pl-4
  pr-2
  select-none
  z-30
  bg-white
  grow-0
  shrink-0
  w-full
  grid
  grid-cols-[max-content_auto_max-content]
  grid-rows-[1fr_auto]
  items-center

  gap-x-3
  md:px-6
  lg:grid-cols-[minmax(max-content,1fr)_minmax(auto,var(--width-4xl))_minmax(max-content,1fr)]
  lg:grid-rows-1
  lg:py-4
  lg:gap-x-6
  print:hidden
`)
  // pl-4
  // pr-4
  // md:px-8
  // lg:py-4
  // xl:px-8
  // 2xl:px-12

  // flex
  // flex-row
  // items-center
  // justify-between
  // mx-auto
  // max-w-4xl
  // gap-4

export default function Navigation() {
  const flag = useFlag('demo:healthline')

  return <>
    <Container data-navigation>
      <a
        title='Go to the DxCheck homepage'
        aria-label='Go to the DxCheck homepage'
        id='home'
        href='/'>

        <img
          className='h-7 -mt-2 w-auto hidden lg:block'
          src={flag ? logoHealthline : logo}
          alt='The text "DxCheck" with "Dx" in red and "Check" in blue. The "D" incorporates a white cross symbol within it.' />

        <img
          className='h-8 lg:hidden'
          src={flag ? logoHealthlineH : logoDx}
          alt='A red "DX" logo with a plus sign integrated into the "D" appears on a transparent background.' />
      </a>

      <ErrorBoundary>
        <Search />
      </ErrorBoundary>

      <ErrorBoundary>
        <NavMenu />
      </ErrorBoundary>
    </Container>

    <div
      aria-hidden='true'
      className='hidden print:flex items-center w-full'>
      <img
        className='h-8 w-auto self-start mt-8 mb-4 ml-4'
        src={logo}
        alt='The text "DxCheck" with "Dx" in red and "Check" in blue. The "D" incorporates a white cross symbol within it.' />
      <div className='flex-1' />
      <div>dxcheck.com</div>
    </div>
  </>
}
