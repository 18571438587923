const Container = styled.footer(`
  w-full
  max-w-4xl
  py-4
  px-4
  mx-auto
  flex
  flex-col
  items-center
  justify-center
  grow-0
  shrink-0
  gap-2
  relative
  print:hidden
  mt-9
`)

const LegalLinks = styled.div(`
  flex
  items-center
  justify-center
  w-full
  flex-wrap
  gap-x-3
`)

const LegalLink = styled.a(`
  text-sm
  text-center
  hover:text-black
  hover:underline
  cursor-pointer
`)

const Copyright = styled.div(`
  text-sm
  text-gray-500
`)


export default function Footer() {
  return (
    <Container>
      <Copyright>&copy; {new Date().getUTCFullYear()} Survey Science, Inc.</Copyright>
      <LegalLinks>
        <LegalLink href='/privacy-policy'>Privacy Policy</LegalLink>
        <LegalLink href='/cookie-policy'>Cookie Policy</LegalLink>
        <LegalLink href='/terms-of-service'>Terms of Service</LegalLink>
        <LegalLink href='/disclaimers'>Disclaimers</LegalLink>
        <LegalLink href='/contact-us'>Contact Us</LegalLink>
      </LegalLinks>
    </Container>
  )
}
