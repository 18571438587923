import type {
  Actions,
  ActionHandlers,
  Methods,
} from './types.js'

export default function buildActions<S, A extends Actions, M extends Methods>(handlers: ActionHandlers<S, A, M>, dispatch: React.Dispatch<any>, isMounted: React.RefObject<boolean>) {
  return Object.mapValues(handlers, (_handler, type) => (...payload: any[]) => {
    if (isMounted.current) {
      return dispatch({ type, payload })
    }
  }) as A
}