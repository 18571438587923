import menuIcon from '&/menu.svg'
import { ButtonPlain } from '@/common/buttons'
import useAuth from '@/auth/useAuth'
import useDevAuth from '@/developer/hooks/useDevAuth'
import useDevMode from '@/developer/hooks/useDevMode'
import { hasAncestor, hasAncestorMatching } from '#/dom/traversal'
import { useClerk } from '@clerk/clerk-react'
import Shimmer from '@/common/Shimmer'
import SwitchButton from '@/common/forms/SwitchButton'


const Container = styled.div(`
  min-w-10
  flex
  items-center
  justify-end
  gap-6
`)


const NavLink = styled.a(`
  cursor-pointer
  font-medium
  text-nowrap
  hidden
  xl:block
`)

const Menu = styled.div(({ isOpen }: { isOpen: boolean }) => [`
  font-medium
  fixed
  right-6
  top-14
  shadow-2xl
  rounded-2xl
  bg-white
  ring-1
  ring-inset
  ring-gray-100
  text-base
  z-50
  min-w-52
  opacity-0
  translate-x-0
  transition-opacity
  duration-200
  forced-colors:outline-1
`, {
  'pointer-events-none': !isOpen,
  'opacity-100': isOpen,
}])

const MenuButton = styled.button(`
  block
  hover:bg-gray-100
  px-3
  py-2
  rounded-xl
  cursor-pointer
  w-full
  text-left
  min-w-56
`)

const MenuLink = styled.a(`
  block
  hover:bg-gray-100
  px-3
  py-2
  rounded-xl
  cursor-pointer
`)

const MenuSection = styled.div(`
  block
  p-2
  [&:not(:first-child)]:border-t-1
  border-t-gray-200
`)



export default function NavMenu() {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const listeners = useEventListeners()
  const auth = useAuth()
  const clerk = useClerk()
  const menu = useRef<HTMLDivElement>(null)

  const box = useBox(_ => ({
    button: _<'button'>(),
  }))

  useEffect(() => {
    if (isMenuOpen) {
      setTimeout(() => {
        listeners.add(window, 'click', event => {
          const showIgnore = (
            event.target instanceof HTMLElement &&
            menu.current &&
            hasAncestor(event.target, menu.current) &&
            hasAncestorMatching(event.target, '[data-toggle]')
          )

          if (!showIgnore) {
            setMenuOpen(false)
          }
        })
      }, 0)

    } else {
      listeners.removeAll()
    }
  }, [listeners, isMenuOpen, box])

  const onSignOut = useCallback(() => {
    void auth.signOut().catch(dx.capture)
  }, [auth])


  const isDev = useDevAuth()

  const {
    inDevMode,
    isPanelOpen,
    togglePanel,
    toggleDevMode,
  } = useDevMode(s => ({
    inDevMode: s.isEnabled,
    togglePanel: s.panel.toggle,
    isPanelOpen: s.panel.isOpen,
    toggleDevMode: s.toggle,
  }))

  return (
    <Container>
      <NavLink
        title='Symptom Checker'
        aria-label='Symptom Checker'
        href='/symptom-checker'>
        Symptom Checker
      </NavLink>

      <NavLink
        title='Conditions'
        aria-label='Conditions'
        href='/health-conditions'>
        Conditions
      </NavLink>

      <NavLink
        title='Assessments'
        aria-label='Assessments'
        href='/health-conditions/assessments'>
        Assessments
      </NavLink>

      <Menu
        isOpen={isMenuOpen}
        ref={menu}>
        {isDev &&
        <MenuSection>
          <SwitchButton
            className='block hover:bg-gray-100 px-3 py-2 rounded-xl w-full text-left min-w-56 justify-between flex gap-2'
            data-toggle
            label={{ position: 'before' }}
            checked={inDevMode}
            onClick={toggleDevMode}>
            Developer Mode
          </SwitchButton>

          {inDevMode &&
            <SwitchButton
              className='block hover:bg-gray-100 px-3 py-2 rounded-xl w-full text-left min-w-56 justify-between flex gap-6'
              checked={isPanelOpen}
              label={{ position: 'before' }}
              onClick={togglePanel}>
              Developer Panel
            </SwitchButton>
          }
        </MenuSection>}

        <MenuSection className='xl:hidden'>
          <MenuLink
            title='Symptom Checker'
            aria-label='Symptom Checker'
            href='/symptom-checker'>
            Symptom Checker
          </MenuLink>

          <MenuLink
            title='Conditions'
            aria-label='Conditions'
            href='/health-conditions'>
            Conditions
          </MenuLink>

          <MenuLink
            title='Assessments'
            aria-label='Assessments'
            href='/health-conditions/assessments'>
            Assessments
          </MenuLink>
        </MenuSection>

        

        {auth.isSignedIn && <MenuSection>
          <MenuButton
            type='button'
            onClick={() => (setMenuOpen(false), setTimeout(() => clerk.openUserProfile(), 0))}>
            Profile
          </MenuButton>

          <MenuButton
            type='button'
            onClick={onSignOut}>
            Sign Out
          </MenuButton>
        </MenuSection>}
      </Menu>

      {auth.imageUrl ?
        <ButtonPlain
          className={classNames('rounded-full p-0 hover:outline-4 hover:outline-gray-100', { 'outline-4 outline-gray-100': isMenuOpen })}
          ref={box.ref('button')}
          onClick={() => setMenuOpen(true)}
          aria-label='Main menu'>
          <Shimmer>
            <img
              aria-hidden='true'
              className='size-9 pointer-events-none rounded-full'
              src={auth.imageUrl}
              alt='Your profile image.' />
          </Shimmer>
        </ButtonPlain> :

        <ButtonPlain
          className='rounded-full p-3 h-10 flex items-center shrink-0 xl:hidden'
          ref={box.ref('button')}
          onClick={() => setMenuOpen(true)}
          aria-label='Main menu'>
          <img
            aria-hidden='true'
            className='h-4 pointer-events-none'
            src={menuIcon} />
        </ButtonPlain>}
    </Container>
  )
}
