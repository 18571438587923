const Container = styled.span(`
  group/shimmer
  relative
  overflow-hidden
  flex
  flex-row
`)

const Shine = styled.span(`
  overflow-hidden
  bg-[rgba(255,255,255,0.36)]
  absolute
  w-1/4
  h-full
  motion-safe:transition
  motion-safe:duration-200
  skew-x-[-45deg]
  translate-x-[-300%]
  z-1
  group-hover/shimmer:translate-x-[600%]
`)

type ShimmerProps = {
  children: React.ReactNode
  className?: string
}

export default function Shimmer(props: ShimmerProps) {
  const {
    children,
    className,
  } = props

  return (
    <Container className={className}>
      <Shine />
      {children}
    </Container>
  )
}
